import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider, Spin } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';

import { Icon } from './components/common';
import AppInit from './AppInit';
import { Controller } from './base-modules/controller';

// Define the default Spinner used in all components
Spin.setDefaultIndicator(<Icon.SpinnerThird spin />);

interface IProps {
  controller?: Controller;
}

const AppWrapper = (props: IProps) => (
  <div id='App' className='App'>
    <Helmet defaultTitle='Mighty' titleTemplate='%s | Mighty'/>
    <ConfigProvider locale={enUS}>
      <BrowserRouter>
        <AppInit {...props} />
      </BrowserRouter>
    </ConfigProvider>
  </div>
);

export default AppWrapper;
