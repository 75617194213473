import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';

import { notification } from 'antd';

import Auth from '../../base-modules/auth';
import Client from '../../base-modules/client';
import {
  getPathFromURL,
  getQuery,
  getQueryFromURL,
  INavigateProps,
  IRedirectTo,
  mergePathAndQuery,
} from '../../utils/navigationUtils';
import { isUUID } from '../../utils/utils';
import { PageLoader } from '../common';
import { URLS } from '../../utils/constants';
import navigationWrapper from '../../utils/navigationWrapper';

function toastShareError (): void {
  notification.error({
    description: (
      <div>
        You do not have permission to view this case. If you believe this is an error,
        please contact the lienholder and let them know you have received this message.
        For further assistance, email <a href='mailto:support@mighty.com'>support@mighty.com</a>.
      </div>
    ),
    duration: 15,
    message: 'Error',
  });
}

interface IInjected extends INavigateProps {
  auth: Auth;
  client: Client;
}

@inject('auth', 'client')
@autoBindMethods
@observer
class SharePage extends Component<INavigateProps> {
  @observable private redirectTo: IRedirectTo = null;

  private get injected () { return this.props as IInjected; }

  public constructor (props: INavigateProps) {
    super(props);
    this.fetchRedirect();
  }

  private async fetchRedirect () {
    this.redirectTo = await this.getRedirectTo();
  }

  private async getRedirectTo (): Promise<IRedirectTo> {
    const { shareableLinkId } = this.props.params
      , { client, auth: { isAuthenticated } } = this.injected
      , isValid = isUUID(shareableLinkId)
      ;

    if (!isAuthenticated) {
      const { law_firm, referral_source } = getQuery()
        , newQuery = { law_firm, referral_source }
        ;

      return mergePathAndQuery(URLS.REGISTRATION_PAGE, newQuery);
    }

    if (!isValid) {
      toastShareError();
      return URLS.PORTFOLIO_PAGE;
    }

    try {
      const url = await client.get(`/share/${shareableLinkId}/`);
      if (!url) { throw new Error(); }
      return mergePathAndQuery(getPathFromURL(url), getQueryFromURL(url));
    }
    catch (err) {
      toastShareError();
      return URLS.PORTFOLIO_PAGE;
    }
  }

  public render () {
    if (this.redirectTo) {
      return <Navigate to={this.redirectTo} />;
    }

    return <PageLoader fullScreen />;
  }
}

export default navigationWrapper(SharePage);
