import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Col, Row } from 'antd';

import CompareCardBody from '../CompareCardBody';
import CompareCardHead from '../CompareCardHead';
import UpgradeLayout from '../UpgradeLayout';
import Auth from '../../../base-modules/auth';
import StoresClass from '../../../stores/StoresClass';
import { ANT_FULL_COL_WIDTH, URLS } from '../../../utils/constants';
import { Button } from '../../common';
import navigationWrapper from '../../../utils/navigationWrapper';
import { getUrlForNewPath, INavigateProps } from '../../../utils/navigationUtils';

import styles from './OverviewPage.module.less';

interface IInjected extends INavigateProps {
  auth: Auth;
  stores: StoresClass;
}

@inject('auth', 'stores')
@autoBindMethods
@observer
class OverviewPage extends Component<INavigateProps> {
  private get injected () { return this.props as IInjected; }

  private get isEligibleForTrial () {
    const { account } = this.injected.stores.users;

    return !!account && account.isEligibleForTrial;
  }

  private async moveToTrial () {
    const { auth, stores: { users: { account } }, navigate } = this.injected;

    if (!account) { return; }

    await account.moveToTrial(auth);
    navigate(URLS.PORTFOLIO_PAGE);
  }

  private redirectToChoose () {
    const { navigate } = this.injected;
    navigate(getUrlForNewPath(URLS.UPGRADE_CHOOSE));
  }

  private onKeepPlan () {
    const { navigate } = this.injected
      , redirect = this.isEligibleForTrial ? URLS.ORGANIZATION_PAGE : URLS.PORTFOLIO_PAGE;
    navigate(getUrlForNewPath(redirect));
  }

  public render () {
    const COLUMN_WIDTH = 7
      , DIVIDER_WIDTH = 2
      , CONTENT_WIDTH = (COLUMN_WIDTH * 2) + DIVIDER_WIDTH
      , CONTENT_OFFSET = (ANT_FULL_COL_WIDTH - CONTENT_WIDTH) / 2
      , title = this.isEligibleForTrial
        ? 'Try enterprise features for 30 days free'
        : 'Upgrade today to keep your access to enterprise features'
      ;

    return (
      <UpgradeLayout
        subtitle='Mighty Plans'
        title={title}
      >
        <Row className={styles.root}>
          <Col span={COLUMN_WIDTH} offset={CONTENT_OFFSET}>
            <CompareCardHead>
              Free Plan
            </CompareCardHead>

            <CompareCardBody>
              <p><strong>$0 per month</strong></p>

              <p>Use our core features for free.</p>
            </CompareCardBody>

            <div className={styles['keep-plan']}>
              <a id='keep-plan-link' onClick={this.onKeepPlan}><b>Keep this plan</b></a>
            </div>
          </Col>

          <Col span={COLUMN_WIDTH} offset={DIVIDER_WIDTH}>
            <CompareCardHead>
              Enterprise
            </CompareCardHead>

            <CompareCardBody>
              <p><strong>Contact us for pricing</strong></p>

              <p>For advanced features and multiple teams.</p>
            </CompareCardBody>

            {this.isEligibleForTrial && (
              <CompareCardBody>
                <a id='start-trial-link' onClick={this.moveToTrial}><b>Start free 30 day trial</b></a>
              </CompareCardBody>
            )}

            {!this.isEligibleForTrial && (
              <Row className={styles.footer}>
                <Button
                  block
                  id='btn-upgrade'
                  onClick={this.redirectToChoose}
                  size='large'
                  type='primary'
                >
                  Upgrade
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </UpgradeLayout>
    );
  }
}

export default navigationWrapper(OverviewPage);
