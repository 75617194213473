import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Card, Col } from 'antd';

import { Form } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import StoresClass from '../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH } from '../../utils/constants';
import { CONTACT_INFO, USER_FIELD_SET } from './utils/fieldSetConfigs';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import styles from './LienholderRegistration.module.less';

interface IInjected extends INavigateProps {
  stores: StoresClass;
}

function AccountDetailsFormHeader () {
  return (
    <div className={styles.header}>
      <h1>Verify your contact information</h1>
      <p>
        Inputting your contact information helps Mighty ensure privacy and security.
      </p>
    </div>
  );
}

@inject('stores')
@autoBindMethods
@observer
class AccountDetailsForm extends Component<INavigateProps> {
  private get injected () { return this.props as IInjected; }

  private async onSave (model: IModel) {
    const { updateCompulsionEvent } = this.injected.stores.caseFormStore
      , { navigate } = this.props
      , redirectTo = await updateCompulsionEvent(model, true)
      ;

    if (redirectTo) {
      navigate(redirectTo);
    }
  }

  public render () {
    const { caseFormStore: { userData } } = this.injected.stores
      , modelData = { ...userData, contactInfo: CONTACT_INFO.WEBSITE }
      , staticFormProps = { blockSubmit: true, saveText: 'Next', successText: null, fieldSets: USER_FIELD_SET };

    return (
      <Col span={ANT_HALF_COL_WIDTH}>
        <Card>
          <AccountDetailsFormHeader />
          <Form model={modelData} onSave={this.onSave} {...staticFormProps} />
        </Card>
      </Col>
    );
  }
}

export default navigationWrapper(AccountDetailsForm);
