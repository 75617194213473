/* eslint-disable no-magic-numbers, max-lines */
import { partition, uniq } from 'lodash';
import { Method } from 'axios';

import { RowProps } from 'antd/lib/row';

import { mapKeyToValue, mapValueToKeyAndValue } from './utils';

export const TOKEN: { [key: string]: number } = {
  CHECK_FREQUENCY: 5 * 60 * 1000, // 5 minutes
  EXPIRATION: 24 * 60 * 60 * 1000, // 24 hours
  REFRESH_MINIMUM: 12 * 60 * 60 * 1000, // 12 hours
};

export const CASE_STATUS_UPDATE_DUE_DAYS = 90;

export const NUM_LIENHOLDER_NOTIFICATION_TYPES = 19;

export const NUM_LAW_FIRM_NOTIFICATION_TYPES = 4;

export const BOOLEAN_STRING = {
  FALSE: 'false',
  TRUE: 'true',
};

export const METHODS: { [key: string]: Method } = {
  DELETE: 'delete',
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
};

export const OPTION_KEYS = {
  ACTIVITY_TYPES: 'activity_types',
  LAW_FIRM_CASE_DOCUMENT_TYPES: 'law_firm_case_document_types',
  LAW_FIRM_DOCUMENT_TYPES: 'law_firm_document_types',
  LAW_FIRM_LIEN_DOCUMENT_TYPES: 'law_firm_lien_document_types',
  LIENHOLDER_DOCUMENT_TYPES: 'lienholder_document_types',
  CASE_STATUS: 'case_status',
  CASE_TYPE: 'case_type',
  DOCUMENT_REQUEST_STATUS: 'document_request_status',
  DOCUMENT_TYPES: 'document_types',
  EXTERNAL_SYSTEM_TYPE: 'external_system_type',
  LAW_FIRM_NOTIFICATION_CATEGORY: 'law_firm_notification_category',
  LEGAL_ORGANIZATION_TYPE: 'legal_organization_type',
  LIEN_REJECTED: 'LIEN_REJECTED',
  LIEN_RESOLUTION_STATUS: 'lien_resolution_status',
  LIENHOLDER_BASE_DOCUMENT_TYPES: 'lienholder_base_document_types',
  LIENHOLDER_REQUEST_STATUS: 'lienholder_lien_document_request_status',
  LIENHOLDER_NOTIFICATION_CATEGORY: 'lienholder_notification_category',
  LIENHOLDER_CASE_STATUS_NOTIFICATION_TYPES: 'lienholder_case_status_notification_types',
  LIENHOLDER_DOCUMENT_REQUEST_NOTIFICATION_TYPES: 'lienholder_document_request_notification_types',
  LIENHOLDER_OTHER_NOTIFICATION_TYPES: 'lienholder_other_notification_types',
  POLICY_LIMIT_TYPES: 'policy_limits',
  REGISTRATION_COMPANY_TYPES: 'registration_company_types',
  REQUEST_STATUS: 'lien_document_request_status',
  TREATMENT_STATUS: 'treatment_status',
  US_STATES: 'us_states',
  VERIFICATION_STATUS: 'lien_verification_status',
};

export const ORGANIZATION_OPTIONS = [
  {
    label: 'My Organization',
    value: BOOLEAN_STRING.TRUE,
  },
  {
    label: 'Other',
    value: BOOLEAN_STRING.FALSE,
  },
];

export const STILL_TREATING_OPTIONS = [
  {
    label: 'Some liens still treating',
    value: BOOLEAN_STRING.TRUE,
  },
  {
    label: 'All liens done treating',
    value: BOOLEAN_STRING.FALSE,
  },
];

export const VERIFICATION_STATUSES = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  RESUBMITTED: 'RESUBMITTED',
  VERIFIED: 'VERIFIED',
};

export const LIEN_REJECTED = {
  [VERIFICATION_STATUSES.REJECTED]: 'Lien Rejected',
};

export const PLAINTIFF_TREATMENT_STATUSES = {
  DONE_TREATING: 'DONE_TREATING',
  TREATING: 'TREATING',
};

export const NON_REJECTED_STATUS_QUERY_STRING =
  `${VERIFICATION_STATUSES.VERIFIED},${VERIFICATION_STATUSES.PENDING},${VERIFICATION_STATUSES.RESUBMITTED}`;

export const NOTIFICATION_TYPES = {
  REGISTRY_LAW_FIRM_DOCUMENT_REQUEST: 'REGISTRY_LAW_FIRM_DOCUMENT_REQUEST',
  REGISTRY_LEGAL_ORGANIZATION_NEEDS_MORE_INFORMATION: 'REGISTRY_LEGAL_ORGANIZATION_NEEDS_MORE_INFORMATION',
  REGISTRY_MISSING_LIENHOLDER_CONTACT: 'REGISTRY_MISSING_LIENHOLDER_CONTACT',
  REGISTRY_LAW_FIRM_CHANGED_BY_LIENHOLDER: 'REGISTRY_LAW_FIRM_CHANGED_BY_LIENHOLDER',
  REGISTRY_LAW_FIRM_CHANGED_BY_LIENHOLDER_FOR_LAW_FIRMS: 'REGISTRY_LAW_FIRM_CHANGED_BY_LIENHOLDER_FOR_LAW_FIRMS',
};

export const CASE_ACTIVITY_TYPES = {
  ATTORNEY_CHANGED: 'ATTORNEY_CHANGED',
  AUTOMATED_CASE_UPDATE_REQUEST: 'AUTOMATED_CASE_UPDATE_REQUEST',
  BILLABLE_LIEN_REGISTERED: 'BILLABLE_LIEN_REGISTERED',
  CASE_CONTACT_CHANGED: 'CASE_CONTACT_CHANGED',
  CASE_CREATED: 'CASE_CREATED',
  CASE_INFO_UPDATED: 'CASE_INFO_UPDATED',
  CASE_MERGED: 'CASE_MERGED',
  CASE_STATUS_NO_CHANGE: 'CASE_STATUS_NO_CHANGE',
  CASE_STATUS_UPDATED: 'CASE_STATUS_UPDATED',
  DOCUMENT_REQUEST_FULFILLED: 'DOCUMENT_REQUEST_FULFILLED',
  HIPAA_DELETED: 'HIPAA_DELETED',
  HIPAA_UPLOADED: 'HIPAA_UPLOADED',
  LAW_FIRM_DOCUMENT_REQUEST: 'LAW_FIRM_DOCUMENT_REQUEST',
  LAW_FIRM_POSTED_NOTE: 'ESCROW_AGENT_POSTED_NOTE',
  LETTER_OF_REQUEST_DELETED: 'LETTER_OF_REQUEST_DELETED',
  LETTER_OF_REQUEST_UPLOADED: 'LETTER_OF_REQUEST_UPLOADED',
  LIABILITY_DOCUMENT_DELETED: 'LIABILITY_DOCUMENT_DELETED',
  LIABILITY_DOCUMENT_UPLOADED: 'LIABILITY_DOCUMENT_UPLOADED',
  LIEN_DELETED: 'LIEN_DELETED',
  LIEN_DOCUMENT_UPLOADED: 'LIEN_DOCUMENT_UPLOADED',
  LIEN_DOCUMENT_VIEWED: 'LIEN_DOCUMENT_VIEWED',
  LIEN_LETTER_DELETED: 'LIEN_LETTER_DELETED',
  LIEN_LETTER_UPLOADED: 'LIEN_LETTER_UPLOADED',
  LIEN_RESOLUTION_STATUS_UPDATED: 'LIEN_RESOLUTION_STATUS_UPDATED',
  LIEN_VERIFICATION_STATUS_UPDATED: 'LIEN_VERIFICATION_STATUS_UPDATED',
  LIENHOLDER_CONTACT_CREATED: 'LIENHOLDER_CONTACT_CREATED',
  LIENHOLDER_FINALIZED_DOCUMENTS: 'LIENHOLDER_FINALIZED_DOCUMENTS',
  LIENHOLDER_POSTED_NOTE: 'LIENHOLDER_POSTED_NOTE',
  PLAINTIFF_TREATMENT_STATUS_UPDATED: 'PLAINTIFF_TREATMENT_STATUS_UPDATED',
};

export const CASE_AUTH_ACTIVITY_TYPES = {
  ACCESS_GRANTED: 'ACCESS_GRANTED',
  ACCESS_REVOKED: 'ACCESS_REVOKED',
  VALID_PHONE_NUMBER_ENTERED: 'VALID_PHONE_NUMBER_ENTERED',
  INVALID_PHONE_NUMBER_ENTERED: 'INVALID_PHONE_NUMBER_ENTERED',
  VALID_VERIFICATION_CODE_ENTERED: 'VALID_VERIFICATION_CODE_ENTERED',
  INVALID_VERIFICATION_CODE_ENTERED: 'INVALID_VERIFICATION_CODE_ENTERED',
  MAX_BIRTHDATE_ATTEMPTS_EXCEEDED: 'MAX_BIRTHDATE_ATTEMPTS_EXCEEDED',
  AUTHENTICATED: 'AUTHENTICATED',
  AUTHENTICATION_FAILED_NO_ACCESS: 'AUTHENTICATION_FAILED_NO_ACCESS',
};

export const NOTE_ACTIVITIES = [
  CASE_ACTIVITY_TYPES.LIENHOLDER_POSTED_NOTE,
  CASE_ACTIVITY_TYPES.LAW_FIRM_POSTED_NOTE,
];

export const DEFAULT_ACTIVITY_CREATED_BY = 'Mighty Import';

export const NOTIFICATION_CATEGORIES = {
  CASE_STATUS_UPDATES: 'CASE_STATUS_UPDATES',
  CASE_STATUS_REQUESTS: 'CASE_STATUS_REQUESTS',
  DOCUMENT_REQUESTS: 'DOCUMENT_REQUESTS',
  NEW_LIEN_NOTIFICATIONS: 'NEW_LIEN_NOTIFICATIONS',
  OTHER: 'OTHER',
};

export const LIENHOLDER_EMAIL_NOTIFICATION_TYPES = {
  REGISTRY_LAW_FIRM_CHANGED: 'REGISTRY_LAW_FIRM_CHANGED',
  REGISTRY_LAW_FIRM_DOCUMENT_REQUEST: 'REGISTRY_LAW_FIRM_DOCUMENT_REQUEST',
  REGISTRY_PLAINTIFF_DONE_TREATING: 'REGISTRY_PLAINTIFF_DONE_TREATING',
  REGISTRY_PLAINTIFF_TREATING: 'REGISTRY_PLAINTIFF_TREATING',
  REGISTRY_COMPILING_MEDICAL_RECORDS: 'REGISTRY_COMPILING_MEDICAL_RECORDS',
  REGISTRY_DEMAND_SENT: 'REGISTRY_DEMAND_SENT',
  REGISTRY_NEGOTIATIONS: 'REGISTRY_NEGOTIATIONS',
  REGISTRY_IN_LITIGATION: 'REGISTRY_IN_LITIGATION',
  REGISTRY_MEDIATION_OR_ARBITRATION_SCHEDULED: 'REGISTRY_MEDIATION_OR_ARBITRATION_SCHEDULED',
  REGISTRY_TRIAL_SCHEDULED: 'REGISTRY_TRIAL_SCHEDULED',
  REGISTRY_CASE_SETTLED_NOT_YET_DISBURSED: 'REGISTRY_CASE_SETTLED_NOT_YET_DISBURSED',
  REGISTRY_CASE_CLOSED_PAYMENT_DISBURSED: 'REGISTRY_CASE_CLOSED_PAYMENT_DISBURSED',
  REGISTRY_CASE_CLOSED_WITHOUT_SETTLEMENT: 'REGISTRY_CASE_CLOSED_WITHOUT_SETTLEMENT',
  REGISTRY_CASE_CLOSED: 'REGISTRY_CASE_CLOSED',
  REGISTRY_LAW_FIRM_ADDED_NEW_LIEN: 'REGISTRY_LAW_FIRM_ADDED_NEW_LIEN',
  REGISTRY_LAW_FIRM_NOTE: 'REGISTRY_LAW_FIRM_NOTE',
  REGISTRY_LAW_FIRM_UPLOADED_DOCUMENT: 'REGISTRY_LAW_FIRM_UPLOADED_DOCUMENT',
  REGISTRY_LIEN_REJECTED: 'REGISTRY_LIEN_REJECTED',
  REGISTRY_LEAD_CASE_STATUS_UPDATE: 'REGISTRY_LEAD_CASE_STATUS_UPDATE',
  REGISTRY_NEW_COMPELLED_LIENHOLDER: 'REGISTRY_NEW_COMPELLED_LIENHOLDER',
  DOCUMENT_REQUEST_UPDATED: 'DOCUMENT_REQUEST_UPDATED',
};

export const LIENHOLDER_EMAIL_CATEGORIES_OPTION_KEYS = {
  [NOTIFICATION_CATEGORIES.CASE_STATUS_UPDATES]: OPTION_KEYS.LIENHOLDER_CASE_STATUS_NOTIFICATION_TYPES,
  [NOTIFICATION_CATEGORIES.DOCUMENT_REQUESTS]: OPTION_KEYS.LIENHOLDER_DOCUMENT_REQUEST_NOTIFICATION_TYPES,
  [NOTIFICATION_CATEGORIES.OTHER]: OPTION_KEYS.LIENHOLDER_OTHER_NOTIFICATION_TYPES,
};

// Non case status update related notification types for lienholders
export const LIENHOLDER_NOTIFICATION_TYPES = {
  REGISTRY_DOCUMENT_REQUEST_UPDATED: 'REGISTRY_DOCUMENT_REQUEST_UPDATED',
  REGISTRY_LAW_FIRM_DOCUMENT_REQUEST: 'REGISTRY_LAW_FIRM_DOCUMENT_REQUEST',
  REGISTRY_LAW_FIRM_CHANGED: 'REGISTRY_LAW_FIRM_CHANGED',
  REGISTRY_LAW_FIRM_NOTE: 'REGISTRY_LAW_FIRM_NOTE',
  REGISTRY_LIEN_REJECTED: 'REGISTRY_LIEN_REJECTED',
  REGISTRY_LAW_FIRM_ADDED_NEW_LIEN: 'REGISTRY_LAW_FIRM_ADDED_NEW_LIEN',
  REGISTRY_LAW_FIRM_UPLOADED_DOCUMENT: 'REGISTRY_LAW_FIRM_UPLOADED_DOCUMENT',
};

export const LIENHOLDER_EMAIL_NOTIFICATION_TYPE_LABELS = {
  [LIENHOLDER_NOTIFICATION_TYPES.REGISTRY_DOCUMENT_REQUEST_UPDATED]: 'Document request updated',
  [LIENHOLDER_NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_DOCUMENT_REQUEST]: 'New document request',
  [LIENHOLDER_NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_CHANGED]: 'Law firm changed on case',
  [LIENHOLDER_NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_NOTE]: 'New note added to case',
  [LIENHOLDER_NOTIFICATION_TYPES.REGISTRY_LIEN_REJECTED]: 'Lien rejected',
  [LIENHOLDER_NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_ADDED_NEW_LIEN]: 'Law firm added new lien',
};

// FIXME: should update the settings API to support disabled by default types more efficiently
export const DISABLED_BY_DEFAULT_NOTIFICATION_TYPES = [
  LIENHOLDER_EMAIL_NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_ADDED_NEW_LIEN,
];

export const ORGANIZATION_TYPES = {
  DEFENDANT_INSURANCE: 'DEFENDANT_INSURANCE',
  FUNDER: 'FUNDER',
  HEALTH_INSURANCE: 'HEALTH_INSURANCE',
  LAW_FIRM: 'LAW_FIRM',
  MEDICAL_PRACTICE: 'MEDICAL_PRACTICE',
  OTHER: 'OTHER',
  REFERRAL_LAW_FIRM: 'REFERRAL_LAW_FIRM',
  SERVICER: 'SERVICER'
};

export const [LIENHOLDER_TYPES, LAW_FIRM_TYPES] = partition(
  Object.keys(ORGANIZATION_TYPES),
  (type: string) => type !== ORGANIZATION_TYPES.LAW_FIRM,
);

export const ROLE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const CLOSED_CASE_STATUSES = {
  CASE_CLOSED: 'CASE_CLOSED',
  CASE_CLOSED_PAYMENT_DISBURSED: 'CASE_CLOSED_PAYMENT_DISBURSED',
  CASE_CLOSED_WITHOUT_SETTLEMENT: 'CASE_CLOSED_WITHOUT_SETTLEMENT',
};

export const STATUS_APPEARANCE_DATE_SIGNIFIER = 'SCHEDULED';
export const IN_LITIGATION = 'IN_LITIGATION';

export const CASE_STATUS_WITH_DATE = {
  CASE_SETTLED_NOT_YET_DISBURSED: {
    field: 'date_expected_to_receive_check',
    label: 'Date expected to receive check',
  },
  IN_LITIGATION: {
    field: 'lawsuit_filed_date',
    label: 'Date lawsuit was filed',
  },
  MEDIATION_OR_ARBITRATION_SCHEDULED: {
    field: 'appearance_date',
    label: 'Appearance date',
  },
  TRIAL_SCHEDULED: {
    field: 'appearance_date',
    label: 'Appearance date',
  },
};

export const CASE_STATUS_FIELDS = [
  ...uniq(Object.values(CASE_STATUS_WITH_DATE).map(status => status.field)),
  'last_updated_at',
  'status',
];

export const PAGE_SIZE = 20;

export const MAX_INITIAL_OPTIONS = 10;

export const DEBOUNCE_DELAY = 300;
export const LONG_DEBOUNCE_DELAY = DEBOUNCE_DELAY * 3;
export const REALLY_LONG_DEBOUNCE_DELAY = DEBOUNCE_DELAY * 10;

export const MODELS = {
  CASE: 'registry.registrycase',
  CONTACT: 'registry.registrycontact',
  LEGAL_ORGANIZATION: 'registry.registrylegalorganization',
  LIEN: 'registry.registrylien',
};

export const EXTERNAL_URL_PROPS = {
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const URLS = {
  ACCOUNT_PAGE: '/settings/account',
  ACCOUNTS_AUTH: '/accounts-auth/',
  BASE_SETTINGS_PATH: '/settings',
  COMMUNICATION_PREFERENCES_PAGE: '/settings/communication',
  CONTACTS_BETA: '/beta/contacts/',
  CONVERT_TOKEN: '/staff-auth/convert-token/',
  DEDUPE_PAGE: '/dedupe',
  DOCUMENT_PAYMENT_PAGE: '/document-payment',
  DOCUMENT_REQUEST_ALREADY_FULFILLED: '/document-request/already-fulfilled',
  DOCUMENT_REQUEST_RESPONSE: '/document-request/response',
  DOCUMENT_REQUEST_RESPONSE_COMPLETE: '/document-request/response-complete',
  DOCUMENT_REQUEST_TOKEN_EXPIRED: '/document-request/token-expired',
  DOCUMENTS_BETA: '/beta/documents',
  EXTERNAL_SYSTEMS_PAGE: '/settings/external-systems',
  INTERNAL_CONTACTS: '/internal/contacts/',
  INTERNAL_DEDUPE_CASES: '/internal/dedupe/cases/',
  INTERNAL_DEDUPE_CONTACTS: '/internal/dedupe/contacts/',
  INTERNAL_LEGAL_ORGANIZATION_SEARCH: '/internal/legal-organization-search/',
  INTERNAL_LEGAL_ORGANIZATIONS: '/internal/legal-organizations/',
  INTERNAL_MANUAL_CASE_DEDUPE: '/internal/manual-dedupe/cases/',
  LAW_FIRM_LIEN_BETA: 'beta/escrow-agent/liens/',
  LAW_FIRM_PORTFOLIO_BETA: 'beta/escrow-agent/portfolio/',
  LEGAL_ORGANIZATIONS: '/legal-organizations/',
  LEGAL_ORGANIZATIONS_LIGHT: '/legal-organizations-light/',
  LIENHOLDER_LIEN_BETA: 'beta/lienholder/liens/',
  LIENHOLDER_PORTFOLIO_BETA: 'beta/lienholder/portfolio/',
  LOGIN: '/login',
  MANUAL_DEDUPE_PAGE: '/manual-dedupe',
  ORGANIZATION_PAGE: '/settings/organization',
  ORGANIZATIONS_BETA: '/beta/organizations/',
  PAYMENT_PAGE: '/upgrade/payment',
  PLAINTIFF: '/plaintiffs',
  PORTFOLIO_PAGE: '/portfolio',
  REFRESH_JWT: '/auth/jwt/refresh/',
  REFRESH_TOKEN: '/staff-auth/token/',
  REGISTER_NOW: '/register-now',
  REGISTRATION_PAGE: '/register',
  REVOKE_TOKEN: '/staff-auth/revoke-token/',
  SHARING: '/share/',
  UPGRADE_CHOOSE: '/upgrade/choose',
  UPGRADE_OVERVIEW: '/upgrade/overview',
  UPGRADE_PAYMENT: '/upgrade/payment',
  STAFF_LOGIN: '/auth/staff-login/',
};

export const SERVICE_STAGES = {
  EXTENDED_TRIAL: 'EXTENDED_TRIAL',
  FREEMIUM: 'FREEMIUM',
  LEAD: 'LEAD',
  PAID: 'PAID',
  TRIAL: 'TRIAL',
};

export const NULL_OPTION = { name: 'None', value: 'NONE' };

export const ANT_FULL_COL_WIDTH = 24;
export const ANT_HALF_COL_WIDTH = ANT_FULL_COL_WIDTH / 2;
export const ANT_THIRD_COL_WIDTH = ANT_FULL_COL_WIDTH / 3;
export const ANT_QUARTER_COL_WIDTH = ANT_FULL_COL_WIDTH / 4;
export const ANT_THREE_QUARTER_COL_WIDTH = ANT_QUARTER_COL_WIDTH * 3;

export const TEST_STRIPE_PUBLIC_KEY = 'pk_test_cWTw8RpHrck8RWVM5ixBAKY3';

export const FEATURES = {
  ADD_CASE: 'ADD_CASE',
  ADD_TAG: 'ADD_TAG',
  AUTOMATED_CASE_TRACKING: 'AUTOMATED_CASE_TRACKING',
};

export const ON_OFF_OPTIONS = [
  { value: BOOLEAN_STRING.TRUE, name: 'On' },
  { value: BOOLEAN_STRING.FALSE, name: 'Off' },
];

export const DEDUPE_STATUS = {
  DISMISSED: 'DISMISSED',
  NEEDS_MORE_INFORMATION: 'NEEDS_MORE_INFORMATION',
  NEW: 'NEW',
};

export const REQUEST_STATUS_VALUES: {[key: string]: string} = {
  LIENHOLDER_UPLOADED_ALL_DOCUMENTS: 'LIENHOLDER_UPLOADED_ALL_DOCUMENTS',
  CLARIFICATION_NEEDED: 'CLARIFICATION_NEEDED',
  PATIENT_STILL_TREATING: 'PATIENT_STILL_TREATING',
  IN_PROGRESS: 'IN_PROGRESS',
  ALREADY_FULFILLED: 'ALREADY_FULFILLED',
};

export const REQUEST_CLOSE_STATUS = {
  CLOSED_BY_LAW_FIRM: 'CLOSED_BY_LAW_FIRM',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
};

export const DOCUMENT_TYPES = {
  CHANGE_OF_REPRESENTATION: 'CHANGE_OF_REPRESENTATION',
  HIPAA_RELEASE: 'HIPAA_RELEASE',
  LETTER_OF_REQUEST: 'LETTER_OF_REQUEST',
  LIABILITY_DOCUMENT: 'LIABILITY_DOCUMENT',
  POLICY_LIMIT: 'POLICY_LIMIT',
  SIGNED_LIEN_LETTER: 'SIGNED_LIEN_LETTER',
};

export const SHARED_LIEN_DOCUMENT_TYPES = [
  DOCUMENT_TYPES.HIPAA_RELEASE,
  DOCUMENT_TYPES.SIGNED_LIEN_LETTER,
];

export const LIENHOLDER_BASE_DOCUMENT_TYPES = {
  MEDICAL_RECORDS: 'MEDICAL_RECORDS',
  BILL: 'BILL',
  LIEN_AGREEMENT: 'LIEN_AGREEMENT',
  OTHER: 'OTHER',
};

export const LIENHOLDER_DOCUMENT_TYPES = {
  ...LIENHOLDER_BASE_DOCUMENT_TYPES,
  ...mapValueToKeyAndValue(SHARED_LIEN_DOCUMENT_TYPES),
};

export const LAW_FIRM_DOCUMENT_TYPES = {
  HIPAA_RELEASE: 'HIPAA_RELEASE',
  LETTER_OF_REQUEST: 'LETTER_OF_REQUEST',
  LIABILITY_DOCUMENT: 'LIABILITY_DOCUMENT',
  SIGNED_LIEN_LETTER: 'SIGNED_LIEN_LETTER',
};

export const LAW_FIRM_LIEN_DOCUMENT_TYPES = [
  LAW_FIRM_DOCUMENT_TYPES.HIPAA_RELEASE,
  LAW_FIRM_DOCUMENT_TYPES.LETTER_OF_REQUEST,
  LAW_FIRM_DOCUMENT_TYPES.SIGNED_LIEN_LETTER,
];

export const LAW_FIRM_CASE_DOCUMENT_TYPES = [
  LAW_FIRM_DOCUMENT_TYPES.LIABILITY_DOCUMENT,
];

export const PRICED_DOCUMENT_TYPES = [
  LIENHOLDER_DOCUMENT_TYPES.MEDICAL_RECORDS,
];

export const POLICY_LIMITS: { [key: string]: { name: string; value: string; } } = mapKeyToValue({
  BODILY_INJURY: {
    name: 'Bodily Injury',
  },
  MEDPAY: {
    name: 'MedPay',
  },
  UI_UIM: {
    name: 'UI/UIM',
  },
});

export const DEFAULT_ROW_PROPS: RowProps = { gutter: 32 };

export const LOCAL_STORAGE = {
  ACCOUNT_ID: 'account-id',
  IMPERSONATED_USER_ID: 'impersonated-user-id',
  PRINT_PERFORMANCE: 'print-performance',
  USER: 'user',
};

export const COOKIE_KEYS = {
  REGISTRY_TOKEN: 'registry-token',
  REGISTRY_REFRESH_TOKEN: 'registry-refresh-token'
};

export const INTEGRATED_EXTERNAL_SYSTEMS = [
  { id: 'filevine', hasLogo: true },
  { id: 'litify', hasLogo: true },
];

export const INTEGRATED_EXTERNAL_SYSTEM_IDS = INTEGRATED_EXTERNAL_SYSTEMS.map(system => system.id);

export const DEV_API_HOST = 'v2.local.mighty.com';

export const REGISTRY_ENVS = {
  DEVELOPMENT: 'development',
  TEST: 'test',
};

export const USER_FLAGS = {
  HIDE_COMPULSION_MARKETING: 'HIDE_COMPULSION_MARKETING',
};

export const CASE_THREAD_TYPES = {
  DOCUMENTS_HIDDEN: 'DOCUMENTS_HIDDEN',
  DOCUMENT_REQUEST: 'DOCUMENT_REQUEST',
  LAW_FIRM_CHANGED: 'LAW_FIRM_CHANGED',
  LIEN_REJECTION: 'LIEN_REJECTION',
};

// To distinguish conversation (back and forth thread types) vs. notification threads
export const CONVERSATION_CASE_THREAD_TYPES = [
  CASE_THREAD_TYPES.DOCUMENT_REQUEST,
];

export const UNIQUENESS_ERROR = 'duplicate key value violates unique constraint';

export const MAX_FILE_SIZE = {
  label: '100mb',
  // 100mb with some room for file information
  value: (100 * (1024 ** 2)) - 1024,
};

export const MAX_ZIP_FILE_COUNT = 100;

export const LOCALHOST = 'local.mighty.com';

export const TERMS_OF_SERVICE_DEFAULT = {
  copy: 'Terms of Service',
  link: 'https://www.mighty.com/tos',
};

export const GOOGLE_OAUTH_APP_NAME = 'Registry';
export const DEV_GOOGLE_CLIENT_ID = '350084720557-tl37gqr04447ufkmemnje5qe62m5s032.apps.googleusercontent.com';

export const AGE_OF_MAJORITY = 18;
