import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';
import { Navigate } from 'react-router-dom';

import { Layout, message } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import Client from '../../base-modules/client';
import { IDocumentSubmitData } from '../../interfaces';
import navigationWrapper from '../../utils/navigationWrapper';
import { getLoginURL, getQuery, getUrl, getUrlForNewPath, INavigateProps } from '../../utils/navigationUtils';
import { ILegacyBaseCase, ILegacyLien, LegacyLien } from '../../models/Legacy';
import { IDocument } from '../../models/Document';
import StoresClass from '../../stores/StoresClass';
import { URLS } from '../../utils/constants';

import { PageLoader } from '../common';

import RequestResponseCaseInfo from './RequestResponseCaseInfo';
import RequestResponseDescription from './RequestResponseDescription';
import RequestResponseForm from './RequestResponseForm';
import RequestResponseInstructions from './RequestResponseInstructions';
import RequestResponseNote from './RequestResponseNote';
import RequestResponseRelease from './RequestResponseRelease';

import styles from './styles/DocumentRequestResponsePage.module.less';

interface ILoggedOutRequest {
  case: Partial<ILegacyBaseCase>;
  created_by_name: string;
  created_by_organization_name: string;
  id: string;
  is_fulfilled: boolean;
  lien: ILegacyLien;
  note: string;
  release: IDocument;
}

interface IInjected extends INavigateProps {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@autoBindMethods
@observer
class DocumentRequestResponsePage extends Component<INavigateProps> {
  @observable private requestData: ILoggedOutRequest | null = null;
  @observable private isLoading: SmartBool = new SmartBool(true);
  @observable private lien?: ILegacyLien;
  @observable private tokenExpired: boolean = false;

  private get injected () { return this.props as IInjected; }

  public componentDidMount () {
    this.fetchInitialData();
  }

  private async fetchInitialData () {
    const { client, stores: { dependencies } } = this.injected
      , { token, request_id } = getQuery()
      ;

    if (!token) {
      message.error('No document request token supplied!');
      return;
    }

    try {
      this.requestData = await client.get(`/logged-out-document-requests/${request_id}/`);

      if (!this.requestData) { return; }

      if (this.requestData.is_fulfilled) {
        this.isLoading.setFalse();
        return;
      }

      this.lien = LegacyLien.create(this.requestData.lien, dependencies);
      this.isLoading.setFalse();
    }
    catch (e) {
      if (get(e, 'response.data.detail') === 'Token has expired') {
        this.tokenExpired = true;
        return;
      }
      throw e;
    }

    this.lien = LegacyLien.create(this.requestData.lien, dependencies);
    this.isLoading.setFalse();
  }

  private async handleSubmit (documents: IDocumentSubmitData[]) {
    const { navigate } = this.props
      , { request_id } = getQuery();

    if (!this.lien) { return; }

    await Promise.all([
      this.lien.bulkUploadDocuments(documents, true),
      this.lien.fulfillLoggedOutDocumentRequest(request_id),
    ]);

    navigate(getUrlForNewPath(URLS.DOCUMENT_REQUEST_RESPONSE_COMPLETE));
  }

  public render () {
    const { token } = getQuery();

    if (!token) {
      return <Navigate to={getLoginURL(getUrl())} />;
    }

    if (this.tokenExpired) {
      return <Navigate to={getUrlForNewPath(URLS.DOCUMENT_REQUEST_TOKEN_EXPIRED)} />;
    }

    if (this.isLoading.isTrue || !this.requestData) {
      return <PageLoader />;
    }

    const {
      case: _case,
      created_by_name,
      created_by_organization_name,
      is_fulfilled,
      note,
      release,
    } = this.requestData;

    if (is_fulfilled) {
      const newUrl = getUrlForNewPath(URLS.DOCUMENT_REQUEST_ALREADY_FULFILLED);
      return <Navigate to={newUrl} />;
    }

    if (!this.lien) {
      return <PageLoader />;
    }

    return (
      <Layout className={styles.root} id='page-document-request-response'>
        <Layout.Content>
          <div>
            <Helmet title='Document Request' />
            <header>
              <div className={styles.logo} />
              <div>Mighty</div>
            </header>
          </div>
          <RequestResponseDescription contact={created_by_name} lawFirm={created_by_organization_name}/>
          <div className={styles.content}>
            <RequestResponseCaseInfo _case={_case}/>
            <RequestResponseNote contact={created_by_name} note={note} />
            <RequestResponseRelease release={release} />
            <RequestResponseInstructions />
            <RequestResponseForm onSubmit={this.handleSubmit} />
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default navigationWrapper(DocumentRequestResponsePage);
