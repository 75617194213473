import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get, isEmpty, isEqual } from 'lodash';
import { getType } from 'mobx-state-tree';

import SmartBool from '@mighty-justice/smart-bool';
import { pluralize } from '@mighty-justice/utils';

import { Col, Drawer, Input, Row } from 'antd';

import { Button, Icon, ModalButton, Table, Spacer } from '../../../common';

import Client from '../../../../base-modules/client';
import StoresClass from '../../../../stores/StoresClass';
import TableStore from '../../../../stores/TableStore';
import { fillInColumnProfile } from '../../../../utils/columnCommon';
import { IVerifyOrganizationMatch } from '../../../../models/DedupeMatch';
import { ORGANIZATION_TYPES } from '../../../../utils/constants';
import { IButtonProps } from '../../../common/ModalButton';

import {
  IVerifiedMatchPortfolio,
  IVerifiedOrganization,
  VerifiedMatchPortfolio,
  VerifiedOrganization,
} from '../../../../models/VerifiedMatchPortfolio';

import { verifiedMatchColumns } from './tableColumns';
import VerifiedMatchModal from './VerifiedMatchModal';

interface IProps {
  client: Client;
  isEditable: boolean;
  isVisible: SmartBool;
  legalOrganizationTypes: string[];
  unverifiedData?: IVerifyOrganizationMatch;
  onSelect?: (match: any) => void;
  stores: StoresClass;
}

const SEARCH_INPUT_WIDTH = 250;

@inject('client', 'stores')
@autoBindMethods
@observer
class VerifiedMatchDrawer extends Component<IProps> {
  private portfolio: IVerifiedMatchPortfolio;
  private store: TableStore;
  @observable private searchValue = '';

  public constructor (props: IProps) {
    super(props);

    this.portfolio = VerifiedMatchPortfolio.create({}, props.stores.dependencies);
    this.store = new TableStore({
      baseQuery: { type: props.legalOrganizationTypes.join(','), is_verified: true },
      client: props.client,
      defaultPageSize: 10,
      filters: { search: get(props.unverifiedData, 'name', '') },
      hydrate: this.portfolio.hydrate,
      ignoredFilters: ['is_verified'],
    });
    this.store.isLoading = true;
  }

  public async componentDidMount () {
    await this.store.fetchTableData();
    this.store.isLoading = false;
  }

  private get isVerifying () {
    return !isEmpty(this.props.unverifiedData);
  }

  private renderCountColumnButton (data: any) {
    const { isEditable, legalOrganizationTypes } = this.props;

    if (isEditable) {
      return (
        <ModalButton
          buttonProps={{ className: 'edit-verified-match', size: 'small' }}
          buttonText='Edit'
          ModalClass={VerifiedMatchModal}
          passThroughProps={{
            isEditing: true,
            legalOrganizationTypes,
            match: data,
            onSave: this.onVerifiedMatchSave,
            title: 'Edit Verified Match Details',
          }}
        />
      );
    }

    return (
      <Button
        onClick={this.selectMatch.bind(this, data)}
        size='small'
        type='primary'
      >
        Select
      </Button>
    );
  }

  private renderCountColumn (_value: any, data: any) {
    const { type } = data
      , countField = type === ORGANIZATION_TYPES.LAW_FIRM ? 'case_count' : 'lien_count'
      , count = data[countField] || 0
      ;

    return (
      <div>
        <Row>
          {count} {pluralize('case', 's', count)} on Mighty
        </Row>
        {this.renderCountColumnButton(data)}
      </div>
    );
  }

  private get columns () {
    return (
      [
        ...verifiedMatchColumns,
        {
          field: '',
          minWidth: 40,
          render: this.renderCountColumn,
        },
      ].map(fillInColumnProfile)
    );
  }

  public selectMatch (data: any) {
    const { isVisible, onSelect } = this.props;

    if (onSelect) { onSelect(data); }
    isVisible.setFalse();
  }

  // istanbul ignore next
  @action
  private onSearchChange (event: any) {
    this.searchValue = event.target.value;
  }

  @action
  private async onSearch (search: string) {
    this.store.onFilterChange({ search });
    await this.store.fetchTableData();
  }

  private onVerifiedMatchSave (
    match: IVerifiedOrganization | IVerifyOrganizationMatch,
    matchData: IVerifiedOrganization,
    isEditing: boolean,
  ) {
    this.store.isLoading = true;

    if (!isEditing) {
      this.portfolio.addMatch(matchData);
    }
    else {
      const matchType = getType(match);

      if (isEqual(matchType, VerifiedOrganization)) {
        (match as any).update(matchData);
      }
      else {
        (match as any).remove();
      }
    }

    this.store.isLoading = false;
  }

  public get drawerTitle () {
    const { isEditable, unverifiedData } = this.props
      , verifyText = unverifiedData ? `verify ${unverifiedData.name}` : 'add a new one'
      , selectingText = `Choose a verified match or ${verifyText}`
      ;

    return isEditable ? 'Edit or add verified matches' : selectingText;
  }

  public render () {
    const { isVisible, legalOrganizationTypes, unverifiedData } = this.props
      , modalButtonProps: IButtonProps = this.isVerifying
        ? { children: <Icon.Edit /> }
        : { children: <Icon.Plus />, type: 'primary' }
      , modalTitle = this.isVerifying ? 'Verify This Legal Organization' : 'Add New Verified Match'
      , colTitleSpan = 14
      ;

    return (
      <Drawer
        className='drawer-dedupe'
        closable
        destroyOnClose
        onClose={isVisible.setFalse}
        placement='right'
        title={'Verified Matches'}
        visible={isVisible.isTrue}
        width='70%'
      >
        <div>
          <Row align='middle' justify='space-between'>
            <Col span={colTitleSpan}>
              <h4>{this.drawerTitle}</h4>
            </Col>
            <Col>
              <Row gutter={16}>
                <Col>
                  <ModalButton
                    buttonProps={modalButtonProps}
                    ModalClass={VerifiedMatchModal}
                    passThroughProps={{
                      isEditing: this.isVerifying,
                      legalOrganizationTypes,
                      match: unverifiedData,
                      onSave: this.onVerifiedMatchSave,
                      title: modalTitle,
                    }}
                  />
                </Col>
                <Col>
                  <Input.Search
                    disabled={this.store.isLoading}
                    onChange={this.onSearchChange}
                    onSearch={this.onSearch}
                    placeholder={'Find verified match'}
                    value={this.searchValue}
                    style={{ width: SEARCH_INPUT_WIDTH }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Spacer small />

          <Table
            {...this.store.tableProps}
            bordered
            columns={this.columns}
            dataSource={this.portfolio.organizations.slice()}
            rowKey='id'
            showHeader={false}
          />
        </div>
      </Drawer>
    );
  }
}

export default VerifiedMatchDrawer;
