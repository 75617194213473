import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { capitalize, omit } from 'lodash';

import { Col, notification, Row } from 'antd';

import { IFieldConfig, IFieldConfigPartial, renderValue, Table } from '@mighty-justice/fields-ant';
import { getNameOrDefault, mapBooleanToText } from '@mighty-justice/utils';
import { IModel, IValue } from '@mighty-justice/fields-ant/dist/props';

import {
  ButtonToolbar,
  Card as AntdCard,
  GuardedButton,
  Icon,
  ModalButton,
  Spacer,
} from '../../common';

import Client from '../../../base-modules/client';
import StoresClass from '../../../stores/StoresClass';
import { BOOLEAN_STRING, ROLE } from '../../../utils/constants';
import { booleanToString } from '../../../utils/utils';
import { ENDPOINTS, ISettingTabProps } from '../settingsUtils';
import { IAccount } from '../../../models/User';

import InviteWrapper from './InviteWrapper';

import styles from './UsersCard.module.less';

interface IInjected extends ISettingTabProps {
  client: Client;
  stores: StoresClass;
}

const FIELD_CONFIG_ROLE: IFieldConfigPartial = {
  field: 'role',
  fromForm: value => (value === BOOLEAN_STRING.TRUE ? ROLE.ADMIN : ROLE.USER),
  label: 'Role',
  options: [{ value: BOOLEAN_STRING.FALSE, name: 'User' }, { value: BOOLEAN_STRING.TRUE, name: 'Admin' }],
  render: capitalize,
  toForm: (value: IValue) => booleanToString(value === ROLE.ADMIN),
  type: 'boolean',
}
  , FIELD_CONFIG_EMAIL: IFieldConfigPartial = {
    field: 'email',
    required: true,
    writeOnly: true,
  }
  , renderActiveColumn = (value: IValue, fieldConfig: IFieldConfig, model: IModel) => {
    if (value === false) {
      return 'Deactivated';
    }

    if (model.is_suspended) {
      return 'Email Inactive';
    }

    return <InviteWrapper fieldConfig={fieldConfig} model={model} render={mapBooleanToText as any} value={value} />;
  }
  ;

@inject('client', 'stores')
@autoBindMethods
@observer
class UsersCard extends Component<ISettingTabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get isLoading () {
    return this.injected.isLoading.isTrue;
  }

  private renderModalHeader (user: IAccount) {
    return (
      <div>
        <h3>
          {getNameOrDefault(user)}<br />
          <small>{renderValue(FIELD_CONFIG_EMAIL, user)}</small>
        </h3>
        <Spacer />
      </div>
    );
  }

  private renderNameAndEmail (_value: IValue, _fieldConfig: IFieldConfig, model: IModel) {
    return (
      <>
        <div>{getNameOrDefault(model)}</div>
        <div className={styles.email}>{model.email}</div>
      </>
    );
  }

  private get userFieldSets (): Array<Array<IFieldConfigPartial & { width?: number }>> {
    const { isAdmin } = this.props
      , { stores } = this.injected
      , { account } = stores.users
      , insertIfAdmin = () => isAdmin
      ;

    return [[
      { field: 'name', render: this.renderNameAndEmail, readOnly: true },
      { field: 'first_name', required: true, writeOnly: true },
      { field: 'last_name', required: true, writeOnly: true },
      FIELD_CONFIG_EMAIL,
      {
        field: 'phone_number',
        type: 'phone',
      },
      {
        ...FIELD_CONFIG_ROLE,
        insertIf: insertIfAdmin,
      },
      {
        field: 'is_active',
        label: 'Active',
        readOnly: true,
        render: renderActiveColumn,
        tableColumnProps: {
          align: 'center',
        },
      },
      {
        field: 'id',
        insertIf: insertIfAdmin,
        label: '',
        readOnly: true,
        render: this.renderEdit,
        width: 115,
      },
      {
        field: 'registry_legal_organization',
        type: 'hidden',
        value: account?.registry_legal_organization?.id,
      },
    ]];
  }

  private renderEdit (id: string, _fieldConfig: IFieldConfig, record: any) {
    const { refresh, account } = this.props
      , onClick = async () => this.deleteUser(id)
      , isCurrentUser = id === account.id;

    if (!record.is_active) {
      return null;
    }

    return (
      <ButtonToolbar align='right' noSpacing>
        <ModalButton
          buttonProps={{ size: 'small' }}
          buttonText={<><Icon.Edit /><span>Edit</span></>}
          passThroughProps={{
            childrenBefore: this.renderModalHeader(record),
            fieldSets: [[FIELD_CONFIG_ROLE]],
            model: record,
            onSave: this.updateUser,
            onSuccess: refresh,
            title: 'Edit User',
          }}
        />
        <GuardedButton
          confirm
          danger
          disabled={isCurrentUser}
          onClick={onClick}
          size='small'
        >
          Deactivate
        </GuardedButton>
      </ButtonToolbar>
    );
  }

  private async submitNewUser (model: IModel) {
    try {
      const { client } = this.injected;

      await client.create(ENDPOINTS.ACCOUNT, omit(model, 'id'));
    }
    catch (error) {
      // TODO: Custom form errors
      const emailError = error.response.data?.email
        , userAlreadyExists = emailError && emailError.includes('User already has an account for this organization.')
        ;

      if (userAlreadyExists) {
        notification.error({
          description: (
            <div>
              <p>A user with this email is already associated with another Mighty account.</p>
              <p>Please reach out to{' '}
                <a href='mailto:support@mighty.com'>support@mighty.com</a>{' '}
                to get this user set up with your organization.
              </p>
            </div>
          ),
          duration: null,
          message: 'Error',
        });
      }

      throw error;
    }
  }

  private async updateUser (model: IModel) {
    await this.injected.client.update(`${ENDPOINTS.ACCOUNT}${model.id}/`, model);
  }

  private async deleteUser (id: string) {
    await this.injected.client.delete(`${ENDPOINTS.ACCOUNT}${id}`);
    this.props.refresh();
  }

  public renderNote () {
    const defaultMessage = `Note: By submitting this form, you are granting access to all of \
          the information and documents in your company’s Mighty portfolio to the recipient. \
          Only invite this user if they are authorized to view this information.`;

    return <p><strong>{defaultMessage}</strong></p>;
  }

  public render () {
    const users = this.injected.stores.settings.users as IModel[];

    return (
      <AntdCard className={styles.root}>
        <Row align='middle' justify='space-between'>
          <Col><h2 style={{ margin: 0 }}>Users</h2></Col>
          <Col>
            <ModalButton
              buttonProps={{
                disabled: this.isLoading,
                type: 'primary',
              }}
              buttonText='Invite New User'
              passThroughProps={{
                childrenBefore: this.renderNote(),
                defaults: { role: ROLE.USER },
                fieldSets: this.userFieldSets,
                onSave: this.submitNewUser,
                onSuccess: this.props.refresh,
                saveText: 'Invite New User',
                title: 'Invite New User',
              }}
            />
          </Col>
        </Row>
        <Spacer />
        <Table
          className='ant-table-simple'
          fieldSets={this.userFieldSets}
          isLoading={this.isLoading}
          model={users}
        />
      </AntdCard>
    );
  }
}

export default UsersCard;
