import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get, isString, sumBy } from 'lodash';
import { Navigate } from 'react-router-dom';

import { Col, Layout, notification, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import AppContent from '../AppContent';
import Client from '../../base-modules/client';
import StoresClass from '../../stores/StoresClass';
import navigationWrapper from '../../utils/navigationWrapper';
import { ErrorContent, PageLoader } from '../common';
import { IDetailLien } from '../../models/Lien';
import { IDocument } from '../../models/Document';
import { URLS } from '../../utils/constants';
import { isUUID } from '../../utils/utils';

import {
  getQuery,
  getUrlForNewPath,
  INavigateProps,
  IURL,
  mergePathAndQuery,
} from '../../utils/navigationUtils';

import CardPaymentSection from './CardPaymentSection';
import DocumentOrderSummary from './DocumentOrderSummary';
import DocumentPaymentPageDescription from './DocumentPaymentPageDescription';
import { IPaymentMethod } from './interfaces';

import styles from './DocumentPaymentPage.module.less';

interface IInjected extends INavigateProps {
  client: Client;
  stores: StoresClass;
}

export function getLawFirmLienDocumentsURL (caseId: string, lienId: string): IURL {
  return mergePathAndQuery(
    URLS.PORTFOLIO_PAGE,
    { id: caseId, lien: lienId, section: 'documents' }
  );
}

@inject('client', 'stores')
@autoBindMethods
@observer
class DocumentPaymentPage extends Component<INavigateProps> {
  @observable private isLoading = new SmartBool();
  @observable private documents: IDocument[] = [];
  // the retrieve method of an escrow agent lien fetch returns a lien with a case
  @observable private lien: IDetailLien | null = null;
  @observable private paymentMethods: IPaymentMethod[] = [];

  private get injected () { return this.props as IInjected; }

  private get isValidUrl () {
    const { document_ids, lien } = getQuery();

    return isUUID(lien) && isString(document_ids) && document_ids.split(',').every((id: string) => isUUID(id));
  }

  public async componentDidMount () {
    const { document_ids, lien } = getQuery()
      , { client, stores: { users } } = this.injected
      ;

    this.isLoading.setTrue();

    if (!users.isLawFirmUser) {
      notification.error({
        description: 'Only law firm users are able to access this page.',
        message: 'Page error',
      });
      this.isLoading.setFalse();
      return;
    }

    if (!this.isValidUrl) {
      notification.error({
        description: 'You must navigate to this page from the lien document table. ' +
          'If you have any questions, please contact support@mighty.com.',
        message: 'Navigation error',
      });
      this.isLoading.setFalse();
      return;
    }

    const [documentResponse, lienResponse] = await Promise.all([
      client.get(`escrow-agent/lien-documents/?id=${document_ids}&page_size=off`),
      client.get(`${URLS.LAW_FIRM_LIEN_BETA}?id=${lien}`),
    ]);

    // TODO - use needs_payment
    this.documents = documentResponse.filter((document: IDocument) => !!document.price && !document.is_paid_for);
    this.lien = lienResponse.results.length ? lienResponse.results[0] : null;

    if (this.lien) {
      this.paymentMethods = await client.get('stripe/payment-methods/');
    }

    this.isLoading.setFalse();
  }

  private get lawFirmName () {
    const { stores: { users } } = this.injected;

    return get(users.registryLegalOrganization, 'name', '');
  }

  private get total () {
    return sumBy(this.documents, 'price');
  }

  private onSuccess () {
    const { navigate } = this.props;

    notification.success({
      description: 'The payment is processing and should be put through shortly. ' +
        'If the documents are unavailable, please refresh your page and try again.',
      duration: 10,
      message: 'Payment processing!',
    });

    if (!this.lien) {
      navigate(URLS.PORTFOLIO_PAGE);
    }

    const { case: caseId, id } = this.lien as IDetailLien;
    navigate(getLawFirmLienDocumentsURL(caseId, id));
  }

  public render () {
    const { stores: { users } } = this.injected;

    if (this.isLoading.isTrue) {
      return <PageLoader fullScreen />;
    }

    if (!users.isLawFirmUser) {
      const newUrl = getUrlForNewPath(URLS.PORTFOLIO_PAGE);
      return <Navigate to={newUrl} replace />;
    }

    if (!this.lien || !this.documents.length) {
      return <ErrorContent />;
    }

    return (
      <AppContent className={styles.root} narrow>
        <Layout className={styles.layout} id='page-document-payment'>
          <DocumentPaymentPageDescription lawFirmName={this.lawFirmName} lienholderName={this.lien.lienholder.name} />
          <Layout.Content>
            <Row justify='space-between'>
              <Col span={10}>
                <CardPaymentSection
                  documentIds={this.documents.map((document: IDocument) => document.id)}
                  onSuccess={this.onSuccess}
                  paymentMethods={this.paymentMethods}
                  total={this.total}
                />
              </Col>
              <Col span={12}>
                <DocumentOrderSummary documents={this.documents} total={this.total} />
              </Col>
            </Row>
          </Layout.Content>
        </Layout>
      </AppContent>
    );
  }
}

export default navigationWrapper(DocumentPaymentPage);
