import React, { Component } from 'react';
import { Col, Layout, Row, Spin } from 'antd';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import autoBindMethods from 'class-autobind-decorator';

import { Form } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import AppContent from '../AppContent';
import AppLayout from '../AppLayout';
import StoresClass from '../../stores/StoresClass';
import { ANT_FULL_COL_WIDTH, ANT_THIRD_COL_WIDTH } from '../../utils/constants';
import { Spacer } from '../common';

import CompulsionRequestTable from './CompulsionRequestTable';

import styles from './InvitePage.module.less';

interface IInjected {
  stores: StoresClass;
}

const EMAIL_FIELD_SETS = [[
  { field: 'to_email', label: 'Provider Email Address', required: true },
]];


@inject('stores')
@autoBindMethods
@observer
class InvitePage extends Component {
  @observable isLoading = new SmartBool(true);

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    const { user } = this.injected.stores.users;
    return user && await this.isLoading.until(user.fetchInvites());
  }

  private async onSave (data: any) {
    const { stores, stores: { users: { user } } } = this.injected;
    if (!user) { return; }
    await this.isLoading.until(user.sendCompulsionRequest(data));
    stores.pointsStore.fetch();
  }

  public render () {
    const { stores: { users: { user } } } = this.injected
      , invites = user ? user.invites : [];

    return (
      <AppLayout>
        <AppContent>
          <Layout className={cx(styles.root, 'page page-floating page-style-card')}>
            <Row>
              <h1 className={styles.header}>
                Invite Providers to Mighty
              </h1>
            </Row>
            <Spacer />
            <Row justify='space-between' className={styles.invite}>
              <Col span={ANT_THIRD_COL_WIDTH}>
                <Form
                  fieldSets={EMAIL_FIELD_SETS}
                  onSave={this.onSave}
                  saveText='Invite Provider'
                  successText='Invited!'
                />
              </Col>
            </Row>
            <Row className={styles.table}>
              <Col span={ANT_FULL_COL_WIDTH}>
                <Spin className={styles.spin} spinning={this.isLoading.isTrue}>
                  <CompulsionRequestTable invites={invites} />
                </Spin>
              </Col>
            </Row>
          </Layout>
        </AppContent>
      </AppLayout>
    );
  }
}

export default InvitePage;
