import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { FormModal } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';
import { getNameOrDefault } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';
import { OPTION_KEYS, REQUEST_STATUS_VALUES } from '../../../../utils/constants';
import StoresClass from '../../../../stores/StoresClass';

interface IProps {
  _case: IDetailCase;
  isVisible: SmartBool;
  onSave: (model: object) => Promise<void>;
  title: string;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class RequestResponseModal extends Component<IProps> {
  @observable private isNoteRequired = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private onSelectStatus (status: string) {
    this.isNoteRequired.set(status === REQUEST_STATUS_VALUES.CLARIFICATION_NEEDED);
  }

  // TODO: remove <br/ > use style code instead
  private get options () {
    const { _case: { plaintiff } } = this.props
      , { options: { getOptions } } = this.injected.stores;

    return (
      getOptions(OPTION_KEYS.LIENHOLDER_REQUEST_STATUS).filter(option =>
        option.value !== REQUEST_STATUS_VALUES.ALREADY_FULFILLED).map(option => {
        if (option.value === REQUEST_STATUS_VALUES.LIENHOLDER_UPLOADED_ALL_DOCUMENTS) {
          return {
            ...option,
            name: (
              <span>
                All neccessary bills and records for {getNameOrDefault(plaintiff)} are<br />
                already uploaded to Mighty
              </span>
            )
          };
        }
        return option;
      })
    );
  }

  private get fieldSets () {
    const { users: { isLawFirmUser } } = this.injected.stores;

    return [[
      {
        editProps: { onChange: this.onSelectStatus },
        field: 'new_status',
        insertIf: () => !isLawFirmUser,
        label: 'Request Status',
        options: this.options,
        required: true,
        showLabel: false,
        type: 'radio',
      },
      {
        field: 'note',
        label: 'Additional Notes',
        required: isLawFirmUser || this.isNoteRequired.isTrue,
      },
    ]];
  }

  public render () {
    const { isVisible, onSave, title } = this.props;

    return (
      <FormModal
        fieldSets={this.fieldSets}
        isVisible={isVisible}
        onSave={onSave}
        saveText='Send'
        title={title}
      />
    );
  }
}

export default RequestResponseModal;
