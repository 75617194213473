import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import jsonToFormData from 'json-form-data';

import { Form, IFieldSetPartial } from '@mighty-justice/fields-ant';

import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { EXTERNAL_URL_PROPS } from '../../utils/constants';
import Client from '../../base-modules/client';
import { TermsOfServiceLink } from '../common';

import FileUploadInput from './FileUploadInput';

const URL_TEMPLATE = (
    'https://mighty-v2-static.s3.amazonaws.com/Mighty+Submission+Template+Updated.xlsx'
  )
  , URL_BAA = 'https://www.mighty.com/baa'
  ;

function BottomText () {
  return (
    <div>
      <p>
        While we accept any file type, we recommend using our
        {' '}<a href={URL_TEMPLATE} {...EXTERNAL_URL_PROPS}>Mighty Case Submission Template</a>.
      </p>
      <p>Upload through this page is secure and HIPAA compliant.</p>
      <p>
        By clicking submit you are agreeing to Mighty's
        {' '}<TermsOfServiceLink />
        {' '}and <a href={URL_BAA} {...EXTERNAL_URL_PROPS}>Business Associate Agreement</a>.
      </p>
    </div>
  );
}

const FIELD_SETS: IFieldSetPartial[] = [[
  { field: 'company_name', required: true },
  { field: 'full_name', label: 'Your Full Name', required: true },
  { field: 'email_address', type: 'email', required: true },
  { field: 'data_file', editComponent: FileUploadInput, label: 'Cases You Are Uploading', required: true },
  { field: 'omit', editComponent: BottomText, showLabel: false },
]];

interface IInjected {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class BulkCaseUploadPage extends Component {
  private get injected () { return this.props as IInjected; }

  private async onSave (model: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { omit, ...data } = model
      , formData = jsonToFormData(data);

    await this.injected.client.create('bulk-data-imports/', formData);
  }

  public render () {
    return (
      <LoggedOutLandingPage title='Secure Case Upload' allowLoggedIn>
        <h1>Secure Case Upload</h1>
        <Form
          blockSubmit
          fieldSets={FIELD_SETS}
          onSave={this.onSave}
          resetOnSuccess
          saveText='Submit'
        />
      </LoggedOutLandingPage>
    );
  }
}

export default BulkCaseUploadPage;
