import React, { Component, FunctionComponent, ReactNode } from 'react';

import { Icon } from '../common';
import { PartialIconProps } from '../common/Icon';

import styles from './ApplicationInfo.module.less';

const INFORMATION: Array<{ icon: FunctionComponent<PartialIconProps>, description: ReactNode }> = [
  {
    icon: Icon.FileMedical,
    description: 'Mighty is a HIPAA-compliant document portal',
  },
  {
    icon: Icon.UsersLight,
    description: 'Thousands of law firms use Mighty to share case status updates',
  },
];

class ApplicationInfo extends Component {
  public render () {
    return (
      <div className={styles.root}>
        {INFORMATION.map(({ icon: InfoIcon, description }, idx) => (
          <div key={idx} className={styles.item}>
            <span className={styles.icon}><InfoIcon /></span>
            <p>{description}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default ApplicationInfo;
