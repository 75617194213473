import React from 'react';

import { Layout } from 'antd';

import AppContent from '../AppContent';

import Spacer from './Spacer';

import styles from './ErrorContent.module.less';

function ErrorContent (_props: object) {
  return (
    <AppContent className={styles.root} narrow>
      <Layout id='page-error'>
        <Layout.Content className={styles.content}>
          There has been an error. Please refresh your tab and try again.
          <Spacer small />
          If the problem persists, please contact support@mighty.com.
        </Layout.Content>
      </Layout>
    </AppContent>
  );
}

export default ErrorContent;
