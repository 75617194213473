import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Layout, { Content, Header } from 'antd/lib/layout/layout';

import { FormCard } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';
import SmartBool from '@mighty-justice/smart-bool';

import Client from '../../base-modules/client';
import { Environment } from '../../base-modules/environment';
import { OPTION_KEYS, URLS } from '../../utils/constants';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';
import { Card } from '../common';
// For some reason, the pattern we have in common/index.ts does not allow us to properly mock the
// members of react-google-recaptcha-v3 in our RegisterNowPage.test.tsx file with jest.mock.
import WithRecaptcha from '../common/WithRecaptcha';

import styles from './RegisterNowPage.module.less';

interface IInjected extends INavigateProps {
  client: Client;
  environment: Environment;
}

@inject('environment', 'client')
@autoBindMethods
@observer
class RegisterNowPage extends Component<INavigateProps> {
  @observable isFormSubmitted = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private get fieldSets () {
    return [[
      { field: 'first_name', required: true },
      { field: 'last_name', required: true },
      { field: 'email', required: true },
      { field: 'company_name', required: true },
      { field: 'customer_source', label: 'How did you hear about Mighty?', required: true },
      {
        field: 'company_type',
        required: true,
        optionType: OPTION_KEYS.REGISTRATION_COMPANY_TYPES,
        type: 'optionSelect',
      },
      { field: 'company_type_specification', label: 'If other, please specify.', type: 'string' },
    ]];
  }

  private async handleVerify (executeRecaptcha: (action: string) => Promise<string>, data: IModel) {
    const recaptchaToken = await executeRecaptcha('submit_form');
    this.handleSubmit(data, recaptchaToken);
  }

  private async handleSubmit (data: IModel, recaptchaToken: string) {
    const { client } = this.injected;

    data.recaptcha_token = recaptchaToken;
    data.recaptcha_action = 'submit_form';

    await client.create('/register-now/', data);

    this.isFormSubmitted.setTrue();
  }

  private navigateToLoginPage () {
    const { navigate } = this.props;

    navigate(URLS.LOGIN);
  }

  public render () {
    const recaptchaKey = this.injected.environment.recaptchaKey;
    return (
      <Layout className={styles['register-now-page']}>
        <Header className={styles.header}>
          <p className={styles.logo} />
          <p className={styles.description}>Manage personal injury liens without the headache.</p>
        </Header>
        <Content className={styles.body}>
          {this.isFormSubmitted.isTrue
            ? (
              <Card bordered={false} className={styles.confirmation}>
                <div className={styles.description}>
                  <p>Thank you for your submission. Your request is currently being verified.</p>
                  <br className={styles['line-break']} />
                  <p>Once verified, you will received an email inviting you to access Mighty.</p>
                </div>
              </Card>
            )
            : (
              <div className={styles['form-card']}>
                <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} useEnterprise={true}>
                  <WithRecaptcha>
                    {(executeRecaptcha: (action: string) => Promise<string>) => (
                      <FormCard
                        className={styles.form}
                        blockSubmit
                        bordered={false}
                        fieldSets={this.fieldSets}
                        onSave={data => this.handleVerify(executeRecaptcha, data)}
                        saveText='Submit'
                        title={
                          <div>
                            <p className={styles.title}>Create your account</p>
                            <p className={styles.description}>
                              Once you are verified, you will get an email with login instructions.
                            </p>
                          </div>
                        }
                      />
                    )}
                  </WithRecaptcha>
                </GoogleReCaptchaProvider>
                <div className={styles['login-link-wrapper']}>
                  <a data-id={'login-link'} onClick={this.navigateToLoginPage}>
                    Already have an account?
                  </a>
                </div>
              </div>
            )
          }
        </Content>
      </Layout>
    );
  }
}

export default navigationWrapper(RegisterNowPage);
