import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Modal } from 'antd';

import { Form, FormManager, FormModal, IEndpointOption } from '@mighty-justice/fields-ant';
import { IBackendValidation, IErrorMessage } from '@mighty-justice/fields-ant/dist/utilities/FormManager';
import SmartBool from '@mighty-justice/smart-bool';

import Client from '../../../../base-modules/client';
import StoresClass from '../../../../stores/StoresClass';
import { ADD_OPTION_KEY, DEFAULT_MODAL_WIDTH, DEFAULT_SELECT_PROPS } from '../tab-contacts/constants';
import { ISearchLegalOrganization } from '../../../../models/Party';
import { LIENHOLDER_TYPES, URLS, LAW_FIRM_TYPES, ORGANIZATION_TYPES } from '../../../../utils/constants';
import { getLienholderOptions } from '../config/fieldSets';
import { optionSelectConfig } from '../../../../utils/configCommon';

import styles from '../tab-contacts/Modal.module.less';

export const DUPLICATE_LIENHOLDER_ERROR = 'The fields case, lienholder must make a unique set.';

export type SelectOrganizationReturnData = {
  id: string;
  name: string;
}

export type onOrganizationClick = (data: SelectOrganizationReturnData) => Promise<void>;

interface IProps {
  isVisible: SmartBool;
  // this could be different from select, but should not be by default
  onCreate?: onOrganizationClick;
  onSelect: onOrganizationClick;
  // this will also include 'all' or some other options
  type?: 'lawFirm' | 'lienholder';
}

interface IInjected extends IProps {
  client: Client;
  getOptions: (key: string) => any[];
  stores: StoresClass;
}

@inject('client', 'getOptions', 'stores')
@observer
@autoBindMethods
class SelectOrganizationModal extends Component<IProps> {
  private isAddingNew = new SmartBool();
  private organizationName = '';
  private formManager?: FormManager;

  private get injected () { return this.props as IInjected; }

  private setRefFormManager (formManager: FormManager) {
    this.formManager = formManager;
  }

  private async createOrganization (model: { [key: string]: any }) {
    const { onCreate, onSelect } = this.props
      , { client } = this.injected
      , createFunction = onCreate ? onCreate : onSelect
      , submitModel = this.type === 'lawFirm' ? { ...model, type: ORGANIZATION_TYPES.LAW_FIRM } : model
      ;

    const response = await client.create(URLS.ORGANIZATIONS_BETA, submitModel);
    await createFunction(response.data);
  }

  private async onObjectSelect (option: { key: string, label: string }) {
    const formManager = this.formManager;

    // istanbul ignore next
    if (!formManager) { return; }

    if (option.key !== ADD_OPTION_KEY) {
      await formManager.onFinish();
    }
  }

  private onAddNew (name: string) {
    this.organizationName = name;
    this.isAddingNew.setTrue();
  }

  private async onSave (formData: any) {
    const { isVisible, onSelect } = this.props
      , { legalOrganization } = formData;

    await onSelect(legalOrganization);
    isVisible.setFalse();
  }

  private processErrors ({ errorMessages, foundOnForm }: IBackendValidation): IBackendValidation {
    const duplicateError = errorMessages.find(error => error.message === DUPLICATE_LIENHOLDER_ERROR);

    if (duplicateError) {
      const HUMAN_READABLE: IErrorMessage = {
        field: '',
        message: 'The lienholder already exists on the case.'
      };

      return {
        errorMessages: [HUMAN_READABLE],
        foundOnForm,
      };
    }

    return { errorMessages, foundOnForm };
  }

  private get type () {
    const { type } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      ;

    if (type) {
      return type;
    }

    // if nothing is passed, then return a different type than the current legal organization
    return isLawFirmUser ? 'lienholder' : 'lawFirm';
  }

  private get searchFilters () {
    if (this.type === 'lawFirm') {
      return { type: LAW_FIRM_TYPES };
    }

    if (this.type === 'lienholder') {
      return { type: LIENHOLDER_TYPES };
    }

    return {};
  }

  private get addNewFieldSets () {
    return [[
      {
        editProps: { autoComplete: 'off' },
        field: 'name',
        label: 'Organization Name',
        value: this.organizationName,
        required: true,
      },
      {
        ...optionSelectConfig,
        field: 'type',
        insertIf: (_model: any) => this.type !== 'lawFirm',
        label: 'Organization Type',
        options: getLienholderOptions(this.injected.getOptions),
        required: true,
        type: 'optionSelect',
      },
      {
        field: 'website',
        label: 'Organization Website',
        type: 'url',
      },
      {
        field: 'phone_number',
        label: 'Organization Phone Number',
        type: 'phone',
      },
    ]];
  }

  public render () {
    const { isVisible } = this.props;

    if (this.isAddingNew.isTrue) {
      return (
        <FormModal
          childrenBefore={
            <p>
              Invite {this.organizationName} to access documents, send messages, and
              view other information on this case
            </p>
          }
          className={styles.modal}
          isVisible={isVisible}
          fieldSets={this.addNewFieldSets}
          onSave={this.createOrganization}
          saveText='Continue'
          successText={null}
          title={`Invite ${this.organizationName} to this case`}
          width={DEFAULT_MODAL_WIDTH}
        />
      );
    }

    return (
      <Modal
        className={styles.modal}
        destroyOnClose
        footer={null}
        title='Search Organizations on Mighty'
        visible={isVisible.isTrue}
        onCancel={isVisible.setFalse}
        width={DEFAULT_MODAL_WIDTH}
      >
        <Form
          className={styles.organizationSearch}
          fieldSets={[[{
            editProps: {
              noSearchContent: `Type in an organization's name`,
              onAddNew: this.onAddNew,
              selectProps: {
                onSelect: this.onObjectSelect,
                ...DEFAULT_SELECT_PROPS.selectProps,
              },
            },
            endpoint: URLS.ORGANIZATIONS_BETA,
            field: 'legalOrganization',
            label: null,
            renderOption: ((option: ISearchLegalOrganization) => (
              <div>
                {option.name}
                <div className={styles.optionInfo}>
                  {option.website}
                </div>
              </div>
            )) as (option: IEndpointOption) => React.ReactNode,
            searchFilters: this.searchFilters,
            // objectSearch so we can pass our own onAddNew
            type: 'objectSearch',
          }]]}
          onSave={this.onSave}
          processErrors={this.processErrors}
          setRefFormManager={this.setRefFormManager}
          showControls={false}
          successText={null}
        />
      </Modal>
    );
  }
}

export default SelectOrganizationModal;
