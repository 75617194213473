import React from 'react';

import { IFieldSetPartial } from '@mighty-justice/fields-ant';

import { addLawFirmField } from '../../add-case-form-drawer/addCaseDrawerConfig';

export const RESOLVE_REJECTION_PATHS = {
  ADD_NOTE: 'ADD_NOTE',
  CHANGE_FIRM: 'CHANGE_FIRM',
  REMOVE_FIRM: 'REMOVE_FIRM',
};

const RESOLVE_REJECTION_PATH_OPTIONS: {value: string, name: string | React.ReactNode}[] = [
  { value: RESOLVE_REJECTION_PATHS.CHANGE_FIRM, name: 'Change the law firm' },
  { value: RESOLVE_REJECTION_PATHS.REMOVE_FIRM, name: 'I don’t know who is representing this client' },
  {
    value: RESOLVE_REJECTION_PATHS.ADD_NOTE,
    name: (
      <p>
        There’s been a mistake! I want to resubmit to the same law firm <br />
        and provide additional information.
      </p>
    ),
  },
];

export const resolveRejectionFieldSets: IFieldSetPartial[] = [[
  {
    field: 'path',
    options: RESOLVE_REJECTION_PATH_OPTIONS,
    showLabel: false,
    type: 'radio',
  },
  {
    ...addLawFirmField,
    field: 'law_firm',
    insertIf: model => (model.path === RESOLVE_REJECTION_PATHS.CHANGE_FIRM),
  },
  {
    field: 'note',
    formValidationRules: {
      required: {
        message: 'This field is required. Please provide additional information to the law firm.',
        required: true,
      },
    },
    insertIf: model => (model.path === RESOLVE_REJECTION_PATHS.ADD_NOTE),
    label: 'Please provide additional information to the law firm:',
    type: 'text',
  },
]] as IFieldSetPartial[];

export const AUTO_THREAD_RESPONSE_NOTE = {
  DISMISSED_THREAD: 'Dismissed thread',
  RELEASED_DOCUMENT: 'Released document',
  UPDATED_LAW_FIRM: 'Updated law firm',
  RESOLVED_REJECTION: 'Resolved rejection',
  RESUBMITTED_LIEN: 'Resubmitted lien',
};

export const THREAD_TYPES = [
  { name: 'Alerts from Mighty', value: 'ALERTS_FROM_MIGHTY' },
  { name: 'Document Request', value: 'DOCUMENT_REQUEST' },
];
