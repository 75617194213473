import React from 'react';

import { Row } from 'antd';

import styles from './DocumentPaymentPage.module.less';

interface IProps {
  lawFirmName: string;
  lienholderName: string;
}

function DocumentPaymentPageDescription (props: IProps) {
  const { lawFirmName, lienholderName } = props;

  return (
    <Row className={styles.description} >
      Prices for medical records are set by {lienholderName}. After paying, {lawFirmName} will have access to
      these records in the documents section of Mighty.
    </Row>
  );
}

export default DocumentPaymentPageDescription;
