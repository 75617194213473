import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { IActivityDetailComponentProps } from './interfaces';

@observer
class AutomatedCaseUpdateRequestActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return (
      <h4>Case update request email sent by Mighty</h4>
    );
  }
}

export default AutomatedCaseUpdateRequestActivity;
