import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Row } from 'antd';

import { formatMoney } from '@mighty-justice/utils';

import CompareCardBody from '../CompareCardBody';
import CompareCardHead from '../CompareCardHead';
import UpgradeLayout from '../UpgradeLayout';
import { Button, LinkButton } from '../../common';
import { PRICE_BUCKETS } from '../common';
import { ANT_THIRD_COL_WIDTH, URLS } from '../../../utils/constants';
import { INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';

import CompareCardSeparator from './CompareCardSeparator';

import styles from './ChoosePlanPage.module.less';

@autoBindMethods
@observer
class ChoosePlanPage extends Component<INavigateProps> {
  private redirectToPayment (event: any) {
    this.props.navigate(`${URLS.UPGRADE_PAYMENT}?selection=${event.target.dataset.selection}`);
  }

  private get footer () {
    return (
      <div className={styles.root}>
        <Row align='middle' justify='start'>
          <Col span={6} offset={2}>
            <div className={styles.back}>
              <LinkButton className={styles.link} href='/upgrade/overview'>BACK</LinkButton>
            </div>
          </Col>
          <Col span={4} offset={12} pull={2}>
            <p>
              For help figuring out the best plan for your business{' '}
              or any questions about billing reach out to us at billing@mighty.com.
            </p>
          </Col>
        </Row>
      </div>

    );
  }

  public render () {
    const SPAN_PER_CASE = 6
      , SPAN_DIVIDER = 3
      , SPAN_BUCKETS = 15
      , COST_PER_CASE = 10
      ;

    return (
      <UpgradeLayout
        subtitle='Mighty Pricing'
        title='Lienholders, choose the perfect plan for your business'
        footer={this.footer}
      >
        <Row className={styles.root}>
          <Col span={SPAN_PER_CASE}>
            <CompareCardHead>
              <div className={styles.largerText}>
                Pay per case
              </div>
            </CompareCardHead>

            <CompareCardBody className={styles.cardBody}>
              Pay as you go<br />
              <br />
              <b className={styles.numCases}>Unlimited cases</b><br />
              Billed monthly<br />
              <span className={styles.perCase}>{formatMoney(COST_PER_CASE)} / case</span>
            </CompareCardBody>

            <Row className={styles.footer}>
              <Button
                data-selection='perCase'
                onClick={this.redirectToPayment}
                size='large'
                type='primary'
              >
                Select
              </Button>
            </Row>
          </Col>

          <Col span={SPAN_DIVIDER}>
            <CompareCardSeparator />
          </Col>

          <Col span={SPAN_BUCKETS}>
            <CompareCardHead>
              <div className={styles.largerText}>
                Pay in advance
              </div>
            </CompareCardHead>

            <Row>
              {PRICE_BUCKETS.map(({ cases, cost, free, total, perCase }, idx) => (
                <Col span={ANT_THIRD_COL_WIDTH} key={idx}>
                  <CompareCardBody className={styles.cardBody}>
                    Buy {cases} cases<br />
                    <span>+{free} free</span><br />
                    <br />
                    <strong className={styles.numCases}>{total} cases<br /></strong>
                    <br />
                    Pay {formatMoney(cost)}<br />
                    <span>{formatMoney(perCase)} / case</span>
                  </CompareCardBody>
                </Col>
              ))}
            </Row>

            <Row>
              {PRICE_BUCKETS.map(({ cases }, idx) => (
                <Col span={ANT_THIRD_COL_WIDTH} className={styles.footer} key={idx}>
                  <Button
                    data-selection={cases}
                    onClick={this.redirectToPayment}
                    size='large'
                  >
                      Select
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </UpgradeLayout>
    );
  }
}

export default navigationWrapper(ChoosePlanPage);
