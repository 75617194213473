import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Link } from 'react-router-dom';

import { Card, Col } from 'antd';

import { Form } from '@mighty-justice/fields-ant';

import StoresClass from '../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH } from '../../utils/constants';
import { EXPERIMENTAL_USER_FIELD_SET } from './utils/fieldSetConfigs';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import styles from './LienholderRegistration.module.less';

interface IInjected extends INavigateProps {
  stores: StoresClass;
}

function EmailRegistrationFormHeader () {
  return (
    <div className={styles.header}>
      <h1>Mighty helps you work better with personal injury law firms</h1>
      <h4>Enter your work email address below to get started.</h4>
    </div>
  );
}

@inject('stores')
@autoBindMethods
@observer
class EmailRegistrationForm extends Component<INavigateProps> {
  private get injected () { return this.props as IInjected; }

  private async onSave (model: any) {
    const { stores: { caseFormStore: { createCompulsionEventFromEmail } } } = this.injected
      , nextUrl = await createCompulsionEventFromEmail(model);

    if (nextUrl) {
      this.props.navigate(nextUrl);
    }
  }

  public render () {
    const { stores: { caseFormStore: { userData } } } = this.injected
      , staticFormProps = { blockSubmit: true, saveText: 'Next', successText: null, model: { ...userData } };

    return (
      <Col span={ANT_HALF_COL_WIDTH}>
        <Card>
          <EmailRegistrationFormHeader />
          <Form fieldSets={EXPERIMENTAL_USER_FIELD_SET} onSave={this.onSave} {...staticFormProps} />
          <div className={styles.loginLink}>If you already have an account, login <Link to='/'>here</Link></div>
        </Card>
      </Col>
    );
  }
}

export default navigationWrapper(EmailRegistrationForm);
