import React from 'react';

import { Row } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import { ButtonProps } from 'antd/lib/button';

import { FormManager } from '@mighty-justice/fields-ant';

import { EXTERNAL_URL_PROPS } from '../../utils/constants';
import ActionItemsListStore from '../../stores/ActionItemsListStore';
import { Button, LinkButton, PageLoader, Spacer, TermsOfServiceLink } from '../common';

import styles from './ActionItemsListModal.module.less';

export const SUBMIT_BUTTON_PROPS: ButtonProps = {
  disabled: true,
  htmlType: 'submit',
  loading: false,
  size: 'large',
  type: 'primary',
};

export const MODAL_PROPS = {
  visible: true,
  width: '700px',
};

export function loadingModalProps (noInfoText: string): React.PropsWithChildren<ModalProps> {
  const children = <PageLoader />
    , footer = (
      <div>
        <Row><LinkButton className={styles['no-information']} disabled>{noInfoText}</LinkButton></Row>
        <Row><Button {...SUBMIT_BUTTON_PROPS} loading>Next</Button></Row>
        <Row><span className={styles.total}>Loading...</span></Row>
      </div>
    );

  return { children, footer };
}

export function allDoneModalProps (noInfoText: string): React.PropsWithChildren<ModalProps> {
  const children = (
      <>
        <Spacer />
        <h1 style={{ textAlign: 'center' }}>All done!</h1>
        <Spacer />
      </>
    )
    , footer = (
      <div>
        <Row><LinkButton className={styles['no-information']} disabled>{noInfoText}</LinkButton></Row>
        <Row><Button {...SUBMIT_BUTTON_PROPS}>Next</Button></Row>
        <Row><span className={styles.total}>You have 0 total</span></Row>
      </div>
    );

  return { footer, children };
}

export function renderFormFooter (
  noInfoText: string,
  formManager: FormManager | undefined,
  store: ActionItemsListStore<any>,
) {
  const noActions = store.noActions
    , isLoading: boolean = !formManager || formManager.isSaving;

  // This is handled by render watching formRefLoading for observer reasons
  if (formManager === undefined) {
    // istanbul ignore next
    return loadingModalProps(noInfoText).footer;
  }

  return (
    <div>
      <Row>
        <LinkButton
          className={styles['no-information']}
          disabled={noActions || formManager.isSaving}
          onClick={store.remove}
        >
          {noInfoText}
        </LinkButton>
      </Row>
      <Row>
        <Button
          {...SUBMIT_BUTTON_PROPS}
          disabled={formManager.isSubmitButtonDisabled || noActions}
          loading={isLoading}
          onClick={formManager.onFinish}
        >
            Next
        </Button>
      </Row>
      <Row>
        <span className={styles.total}>You have {store.total()} total</span>
      </Row>
    </div>
  );
}

export const TermsAndConditions = () => (
  <>
    <Spacer />
    <small className='terms-statement'>
      By submitting, you are agreeing to Mighty's{' '}
      <TermsOfServiceLink />{' '}
      and{' '}
      <a href='https://www.mighty.com/baa' {...EXTERNAL_URL_PROPS}>Business Associate Agreement.</a>
    </small>
  </>
);
